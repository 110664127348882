var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.legalPopupCoresitesV1 = {
    templateContainer: $('.js-legal-popup-coresites'),
    initlegalPopupCoresites: function () {
      var self = this;
      var $cookieName = $('.js-legal-popup-coresites-cookie').val();
      var $cookie = $.cookie($cookieName);

      $cookie = parseInt(isNaN($cookie) || !$cookie ? 0 : $cookie);
      var showPopup = $cookie === 0;
      var closeLegalPopupCoresites = function () {
        setTimeout(function () {
          self.templateContainer.slideUp(600);
        }, 0);
      };
      var openLegalPopupCoresites = function () {
        setTimeout(function () {
          self.templateContainer.slideDown(600);
        }, 0);
      };

      // If the user should see the notice, open it
      if (showPopup) {
        openLegalPopupCoresites();
      }
      // Bind to the close/x button
      $('.js-legal-popup-coresites-close', self.templateContainer).once().click(function () {
        closeLegalPopupCoresites();

        // Set the cookie after they chose to not see the notice any more
        $.cookie($cookieName, 1, { path: '/' });
      });
    },
    attach: function (context) {
      var self = this;

      self.initlegalPopupCoresites(context);
    }
  };
})(jQuery);
